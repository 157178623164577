@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.form {
  display: flex;
  flex-direction: column;

  .title {
    @extend %sectionTitle;
    margin-bottom: $spacing-m;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
