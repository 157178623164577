@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/z-index.scss";

$timeShort: 0.2s;

.wrapper {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $popup;

  .outerContainer {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 70rem;

    &.small {
      width: 40rem;
    }

    .modal {
      overflow: hidden;
      max-height: 90vh;
      margin: $spacing-m;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: $borderRadius;
      @include boxShadow(3);
      animation-duration: $timeShort;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      animation-name: fadeContent;

      .closeButton {
        position: absolute;
        top: 0;
        right: 0;
        background: $affordance;
        border: none;
        border-radius: 100%;
        color: white;
        height: 3.2rem;
        width: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2; //For button not to be covered by scrollbar
        &:hover,
        &:focus {
          background: $primaryDark;
        }
        &:focus,
        &:active {
          border: 0;
          outline: none;
        }
        svg {
          color: inherit;
        }
      }
      .title {
        padding: 0 $spacing-l;
        font-weight: 500;
        height: 7rem;
        min-height: 7rem;
        background: #cfeaf1;
        display: flex;
        align-items: center;
        border-radius: $borderRadius $borderRadius 0 0;
        margin-bottom: 0;
      }

      .content {
        overflow-y: auto;
        padding: $spacing-l;
      }

      .footer {
        border-top: 1px solid $borderColorLight;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-m;
      }
    }

    @include small-screen {
      margin: auto $spacing-s;
      .modal {
        max-height: calc(95vh - (#{$spacing-m} * 2));
      }
      &.small {
        width: 100%;
      }
    }
  }
}

.disableScroll {
  overflow: hidden;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeContent {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
