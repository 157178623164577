@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";
@import "../../../style/z-index.scss";

%content {
  flex-grow: 1;
  flex-basis: 0;
}

.container {
  margin-top: $spacing-xxl;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include boxShadow(2);
  background-color: $white;

  @include medium-screen {
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    gap: 4.8rem;
  }
  @include small-screen {
    grid-template-columns: 1fr;
  }

  .contentContainer {
    @extend %content;
    padding: $spacing-xxl;
    @include medium-screen {
      padding: 1.5rem;
    }
    @include small-screen {
      padding: 1.5rem;
    }
  }

  .textContainer {
    .sectionTitle {
      @extend %sectionTitle;
    }
    .text {
      white-space: pre-wrap;
    }
  }

  .infoContainer {
    @include medium-screen {
      grid-column: 1;
      grid-row: 2;
    }
  }

  .image {
    margin-top: $spacing-xxl;

    @include medium-screen {
      align-self: start;
    }
  }
  .imageContainer {
    align-self: center;
    display: flex;
    justify-content: center;
    @include medium-screen {
      width: 80%;
      align-self: start;
    }
    @include small-screen {
      width: auto;
      justify-self: center;
    }
  }
}
