@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";
@import "../../../../../style/mixins.scss";

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.title {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeMedium;
  padding: 1.6rem 0;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include small-screen {
    grid-template-columns: 1fr;
  }
}
