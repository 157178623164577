@import "../../../style/typography.scss";

.passwordContainer {
  max-width: 500px;
  margin: 0 auto;

  .title {
    @extend %sectionTitle;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
