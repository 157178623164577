@import "../../../style/mixins.scss";
@import "../../../style/z-index.scss";
@import "../../../style/colors.scss";

.imgWrapper {
  .image {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
}
.notOrderable {
  position: absolute;
  top: 0px;
  left: 0px;
  color: $fontColor;
  background-color: $lightYellow;
  padding: 0.3rem;
}
