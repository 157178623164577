@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";

%menuItemStyle {
  text-transform: uppercase;
  color: $fontColor;
  font-weight: $fontWeightMedium;
  font-size: $fontSizeSmall;
  position: relative;
  height: 100%;
  padding: 0 1rem;
  &:hover {
    color: $white;
    background-color: $primaryDark;
    @include transition(all, 0.3s, ease-in-out);
  }
  &.active {
    color: $white;
    background-color: $primaryDark;
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 8px solid $navigationSubMenu;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:focus {
    outline: none;
    border: none;
    color: $primary;
  }
}

.navBar {
  background: $navigationBar;
  height: 4rem;

  .navContent {
    @include content-container;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .searchField {
      max-width: 30rem;
    }
    .menu {
      display: flex;
      height: 100%;
      .menuItem {
        padding: 0 $spacing-m;
        @include medium-screen {
          padding: 0 $spacing-m;
        }
        &:first-child {
          padding-left: 0;
        }
        .menuButton {
          @extend %menuItemStyle;
          background: none;
          border: none;
        }
        .menuLink {
          @extend %menuItemStyle;
          text-decoration: none;
          display: inline-block;
          height: 100%;
          display: flex;
          align-items: center;
          &.selected {
            color: $white;
            background-color: $primaryDark;
          }
        }
      }
    }
  }
  .submenuWrapper {
    background-color: $navigationSubMenu;
    @include boxShadow(1);

    .subMenuContent {
      @include content-container;
      display: flex;
      min-height: 19.2rem; //this will most probably change

      .subMenu {
        padding-right: 6rem;
        margin-top: $spacing-xl;
        margin-bottom: $spacing-xl;

        &:not(:last-child) {
          margin-right: 6rem;
          border-right: 1px solid $primary;
        }

        .title {
          font-weight: $fontWeightMedium;
          margin-bottom: $spacing-l;
        }

        .link {
          margin-bottom: $spacing-m;
          a {
            color: inherit;
            text-decoration: none;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
