@import "../../style/mixins.scss";
@import "../../style/spacing.scss";

.page {
  .welcomeSegment {
    margin-bottom: 4.8rem;
  }
  .companyLogoSegment {
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5;
  }

  @include medium-screen {
    .welcomeSegment,
    .companyLogoSegment {
      margin-bottom: 6.4rem;
    }
  }
}
