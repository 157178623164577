@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.pageContent {
  display: grid;
  grid-template-columns: 24rem minmax(0, 1fr);
  gap: 32px;
  min-height: 50vh; //TBD
  @include boxShadow(2);
  background-color: $white;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 1.6rem;
  }
  .navigation {
    background: $pageSubMenu;
    width: 100%;
    a {
      color: $darkGrey;
    }

    ul {
      padding: 8px 0;
    }

    .navButton {
      padding: 4px 16px;
    }
    .active {
      color: $affordance;
    }
  }
}
