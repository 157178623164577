@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/z-index.scss";

.header {
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: $header;
  border-bottom: 1px solid $darkWhite;
  .topBar {
    border-bottom: 1px solid $darkWhite;
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-m;
    align-items: center;
  }
  .logo {
    height: 4rem;
  }

  .actionBar {
    padding: $spacing-s $spacing-m;
    display: flex;
    justify-content: space-between;
    .receiverMenu {
      margin-left: 0;
    }
    .orderMenuWrapper {
      margin-left: auto;
    }
    .orderMenu {
      margin-right: 0;
    }
  }

  .searchContainer {
    height: 4px;
    background: $navigationBar;
  }
}
