@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.page {
  @include content-container;
  margin-bottom: 4.8rem;

  .headerSection {
    margin-bottom: $spacing-l;

    .displayRefreshButton {
      margin-top: $spacing-l;
      display: grid;
      grid-template-columns: 2fr 1fr;
      .button {
        display: flex;
        justify-content: right;

        .refreshButton {
          display: flex;
          align-items: center;
          .buttonText {
            font-size: $fontSizeSmall;
            padding-left: $spacing-s;
          }
        }
      }
    }
    .title {
      @extend %pageTitle;
      margin-bottom: 0;

      .description {
        font-weight: $fontWeightLight;
      }
    }
  }
  .noTitle {
    margin-bottom: 0 !important;
  }

  &.oneToOne {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    .headerSection {
      grid-column: span 2;
      margin: 0;
    }
  }

  &.spaceBetween {
    gap: $spacing-l 10rem;

    @media (max-width: 1000px) {
      gap: $spacing-l $spacing-xxl;
    }
  }
}

.embedded {
  margin: $spacing-xxl;
}

@include small-screen {
  .page {
    margin-bottom: 8rem;

    &.oneToOne {
      grid-template-columns: minmax(0, 1fr);

      .headerSection {
        grid-column: 1;
      }
    }
  }
}
