@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/z-index.scss";
@import "../../../style/typography.scss";

.header {
  width: 100%;
  position: fixed;
  height: $headerHeight;
  background-color: $white;
  z-index: $header;
  @include boxShadow(1);

  .userInfo {
    padding: $spacing-xs 0;
    border-bottom: 1px solid $darkWhite;
  }
  .userContent {
    @include content-container;
    display: flex;
    justify-content: flex-end;
    height: 2.4rem;
  }
  .headerContent {
    max-width: $maxContentWidth;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $spacing-s;

    .logoContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .imageContainer {
        width: 75px;
        img {
          width: 100%;
        }
      }
      span {
        font-size: $fontSizeExtraLarge;
        font-weight: $fontWeightMedium;
      }
    }

    .rightMenu {
      display: flex;
    }

    .cartLink {
      color: inherit;
      text-decoration: none;
      display: flex;
      align-items: center;
      &:hover {
        color: $affordance;
      }
      .icon {
        margin-left: $spacing-m;
        width: $iconMediumSize;
      }
    }

    .hidden {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
}
