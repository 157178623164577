@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/z-index.scss";

.snackbar {
  z-index: $errorMessage;
  position: fixed;
  left: $spacing-s;
  right: $spacing-s;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-name: fade;

  &.bottom {
    bottom: $spacing-xl;
  }

  &.top {
    top: $spacing-s;
  }

  &.right {
    top: $spacing-s;
    left: auto;
  }

  .toast {
    max-width: $max-width;
    color: $white;
    font-weight: 500;
    padding: $spacing-s $spacing-m;
    display: grid;
    grid-template-columns: $iconMediumSize 1fr $iconMediumSize;
    gap: $spacing-m;
    align-items: flex-start;
    border-radius: $borderRadius;
    @include boxShadow(2);

    animation-duration: 200ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &.bottom {
      animation-name: fromBottom;
    }

    &.top {
      animation-name: fromTop;
    }

    &.right {
      animation-name: fromRight;
    }

    &.error {
      background-color: $error;
    }
    &.warning {
      background-color: $warning;
    }
    &.info {
      background-color: $info;
    }
    &.success {
      background-color: $success;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: $iconMediumSize;
      width: $iconMediumSize;

      .info {
        transform: rotate(180deg);
      }
    }

    .message {
      margin: 0 $spacing-m;
    }

    .closeBtn {
      color: $white;
      transition: color 250ms;
      &:hover {
        color: rgba(0, 0, 0, 0.35);
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
