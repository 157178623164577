@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.serviceTypeCard {
  .title {
    text-transform: uppercase;
    font-weight: $fontWeightMedium;
    margin-bottom: 0;
    word-break: break-word;
    text-align: center;

    @include small-screen {
      margin-top: $spacing-s;
      font-size: $fontSizeSmall;
    }
  }
  .number {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightLight;
    margin: 0;
  }
}

.serviceTypeLink {
  color: currentColor;
  display: block;
  padding: $spacing-m;
  border-radius: $borderRadiusSmall;
  &:hover {
    text-decoration: none;
    color: $affordance;
  }
}

.listItem {
  margin: 1.2rem;
  &:hover {
    @include boxShadow(2);
    @include transition(all, 0.3s, ease-in-out);
  }
}
