@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 50%;
    margin-right: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: $primaryLight2;
    border-radius: $borderRadius;
    padding: $spacing-m;
    color: currentColor;
    @include boxShadow(1);
    &::placeholder {
      opacity: 1;
    }
    &:focus::placeholder {
      opacity: 1;
    }
    &:focus {
      @include boxShadow(2);
      @include transition(all, 0.3s, ease-in-out);
    }

    &:hover {
      border-color: transparent;
    }
    &:focus,
    &:active,
    &:focus-within {
      outline-width: 1px;
      outline-color: transparent;
    }
  }
}

.serviceTypesList {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: $spacing-l;
  margin: $spacing-xl 0;
  background-color: $white;
  @include boxShadow(2);
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
