@import "../../../../style/colors.scss";
@import "../../../../style/spacing.scss";

.link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  color: $fontColor;
  margin-bottom: $spacing-m;

  p {
    margin-bottom: 0;
    margin-left: $spacing-s;
    word-break: break-word;
  }

  sub {
    margin-bottom: 0;
    margin-left: $spacing-s;
    color: $subfontColor;
  }

  .subCreatedBy {
    margin-left: $spacing-m;
  }

  .title {
    line-height: $spacing-m;
  }
}
