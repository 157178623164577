@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/z-index.scss";

.navigation {
  background: $pageSubMenu;

  a {
    color: $darkGrey;
  }

  ul {
    padding: 8px 0;
  }

  .navButton {
    padding: 4px 16px;
  }
  .active {
    color: $affordance;
  }

  @include small-screen {
    margin: 0 -16px;
    top: $mobileFullHeaderHeight;
    z-index: $subMenu;

    @include large-mobile {
      top: calc(#{$mobileFullHeaderHeight});
    }

    .scrollWrapper {
      overflow-x: scroll;
      background: $pageSubMenu;
      display: flex;
      flex-direction: column;
      padding: 10px 0;

      &.showArrows {
        padding: 10px 20px;
      }
    }

    ul {
      padding: 0;
      display: flex;
      width: max-content;
    }

    .left {
      position: absolute;
      top: 25%;
      background: $pageSubMenu;
    }

    .right {
      position: absolute;
      top: 25%;
      right: 0;
      background: $pageSubMenu;
    }
  }
}
