@import "../../../style/mixins.scss";

.paginationRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include small-screen {
    flex-direction: column-reverse;
  }
}

.pageSize {
  padding: 0 2rem !important;
  display: flex;
  align-items: center;

  input {
    border: none !important;
  }
}
