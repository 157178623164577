@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.sectionTitle {
  @extend %sectionTitle;
}

.fileList {
  margin-bottom: $spacing-xl;
  @include small-screen {
    margin-bottom: 6.4rem;
  }
}
