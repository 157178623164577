@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/sizes.scss";

%menuItemStyle {
  color: $fontColor;
  font-size: $fontSizeMedium;
  position: relative;
  height: 100%;
  padding: $spacing-l $spacing-l $spacing-m;
  display: block;
  &:hover {
    color: $primary;
  }
  &.active {
    color: $primary;

    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 8px solid $navigationSubMenu;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:focus {
    outline: none;
    border: none;
    color: $primary;
  }
}

.drawerMenu {
  background: $navigationSubMenu;
  max-height: calc(100vh - #{$mobileMenuHeight});

  .navContent {
    .searchField {
      max-width: 30rem;
    }
    .menu {
      height: 100%;
      .menuItem {
        padding: 0 $spacing-l;
        @include medium-screen {
          padding: 0 $spacing-m;
        }
        .menuButton {
          @extend %menuItemStyle;
          background: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .menuLink {
          @extend %menuItemStyle;
          text-decoration: none;
          height: 100%;
          &.selected {
            color: $primary;
          }
        }
      }
    }
  }
}

.submenuWrapper {
  .subMenuContent {
    @include content-container;

    .subMenu {
      padding-left: $spacing-xl;
      margin-top: $spacing-s;
      margin-bottom: $spacing-s;

      .title {
        font-weight: $fontWeightMedium;
        margin-bottom: $spacing-m;
      }

      .link {
        margin-top: $spacing-s;
        margin-bottom: $spacing-l;
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

.version {
  text-align: right;
  color: rgba(0, 0, 0, 0.1);
  padding: 1rem 3rem;
}
