@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.breadcrumbs {
  font-size: $fontSizeSmall;
  margin-top: -$spacing-m;
  margin-bottom: $spacing-s;
  ol {
    display: flex;
    align-items: center;
    li:not(:last-child) {
      &::after {
        margin: 0 $spacing-xs;
        content: " / ";
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}
.displayRefreshButton {
  margin-top: $spacing-xs;
  display: grid;
  grid-template-columns: 2fr 1fr;
  .button {
    display: flex;
    justify-content: right;

    .refreshButton {
      display: flex;
      align-items: center;
      .buttonText {
        padding-left: $spacing-s;
      }
    }
  }
}
