@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";

.text {
  margin-bottom: $spacing-s;
  span {
    display: block;
  }

  .label {
    font-weight: $fontWeightLight;
  }

  .inline {
    display: inline;
    margin-right: $spacing-s;
  }
}
