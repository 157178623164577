@import "../../../../style/colors.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/z-index.scss";

.menuWrapper {
  padding: 0 $spacing-m;

  .userButton {
    display: flex;
    font-size: $fontSizeSmall;
    text-align: left;

    .userIcon {
      margin: 0 $spacing-s;
    }
  }

  .title {
    font-size: $baseFontSize;
  }

  .submenuWrapper {
    position: absolute;
    right: -8px;
    padding-top: 12px;

    .userMenu {
      background: $navigationSubMenu;
      z-index: $flyout;
      padding: $spacing-m;
      position: relative;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      border-radius: 2px;

      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 12px solid $navigationSubMenu;
        top: -12px;
        right: 0;
      }

      li {
        padding: $spacing-s;
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: $affordance;
          }
        }
        > * {
          width: 100%;
        }
      }

      .menuLinkItem {
        padding: $spacing-s;
      }

      .menuButtonItem {
        padding: $spacing-s;
        width: 100%;
      }
    }
  }
}
