@import "../../style/sizes.scss";
@import "../../style/mixins.scss";

.main {
  padding-top: calc(#{$headerHeight} + 5.4rem);
  @include small-screen {
    padding-top: calc(#{$mobileReducedHeaderHeight} + 3.2rem);
    &.loggedInMobile {
      padding-top: calc(#{$mobileFullHeaderHeight} + 3.2rem);
    }
  }
}
