@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.filters {
  margin-bottom: $spacing-l;
}

.generalSearch {
  display: flex;
  align-items: center;
  padding-bottom: $spacing-l;

  .buttons {
    display: flex;
    @include small-screen {
      .mobileSearch {
        min-width: 2rem !important;
      }
    }
  }

  .icon {
    margin: 0 $spacing-m;
    min-width: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.searchButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacing-s;

  & > * {
    margin: $spacing-s;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.clearButton {
  & > * {
    min-width: 0 !important;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.noBorder {
  border: none !important;
}

.noErrorField {
  p {
    display: none;
  }
}
