@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/z-index.scss";

.menu {
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid $darkWhite;
  background: white;
  padding: $spacing-s 0;
  z-index: $footerMenu;
  display: flex;
  .link {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5;
    color: inherit;
    border: none;
    background-color: transparent;

    &.active svg {
      color: $affordance;
    }

    & .text {
      font-size: 1.1rem;
      white-space: nowrap;
    }
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline-color: transparent;
    }
  }
}
